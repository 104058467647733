import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './Clock.scss';

export default function Clock() {
  const [initialTime, initialTimeSetter] = useState(moment());
  const [currentTime, currentTimeSetter] = useState(moment());
  const [glow, glowSetter] = useState(false);
  const [interval, intervalSetter] = useState(null);

  useEffect(() => {
    initialTimeSetter(moment());
    // Set up timer to update the clock text
    intervalSetter(setInterval(() => {
      currentTimeSetter(moment());
      glowSetter(!glow);
    }, 1000));
    return () => {
      // Clean up
      clearInterval(interval);
    }
  }, []);

  return (
    <div id="Clock">
      <div className="shadow"></div>
      <div className="indicator-container" id="indicatorContainer">
        {Array.from({ length: 60 }).map((_, i) => (
          <div key={i} className={`indicator ${i % 5 === 0 ? 'majorIndicator' : ''}`} style={{ transform: `rotateZ(${i * 6}deg)` }}></div>
        ))}
      </div>
      <div className="hours-container">
        <div className="hours" style={{ transform: `rotateZ(${initialTime.hours() * 30 + (initialTime.minutes() / 2)}deg)` }}></div>
      </div>
      <div className="minutes-container">
        <div className="minutes" style={{ transform: `rotateZ(${initialTime.minutes() * 6 + (initialTime.seconds() / 10)}deg)` }}></div>
      </div>
      <div className="seconds-container">
        <div className="seconds" style={{ transform: `rotateZ(${initialTime.seconds() * 6}deg)` }}></div>
      </div>
      <div id="InfoFace" className={glow ? 'glowing' : ''}>
        <p className="CurrentDate" id="currentDate">{currentTime.format('YYYY-MM-DD')}</p>
        <p className="CurrentTime text-bright" id="currentTime">{currentTime.format('HH:mm:ss')}</p>
      </div>
    </div>
  );
}
