import React, { useState, useEffect } from 'react';
import 'particles.js';
import './Now.scss';
import { LuClock4, LuExpand } from 'react-icons/lu';
import { BiCollapse } from 'react-icons/bi';
import Clock from './Clock';
import Stopwatch from './Stopwatch';
import { IoMdStopwatch } from 'react-icons/io';

export default function Now() {
  const [fullScreen, fullScreenSetter] = useState(false);
  const [page, pageSetter] = useState('Clock');

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      fullScreenSetter(true);
      document.documentElement.requestFullscreen();
    } else {
      fullScreenSetter(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    window.particlesJS.load('particles', './particles.json');
  }, []);

  return (
    <div className="Now">
      <h1 className="pageTitle">{page}</h1>
      {page === 'Clock' ? (
        <button className="pageToggleButton" onClick={() => pageSetter('Stopwatch')}>
          <IoMdStopwatch />
        </button>
      ) : (
        <button className="pageToggleButton" onClick={() => pageSetter('Clock')}>
          <LuClock4 />
        </button>
      )}
      <button className="fullScreenButton" onClick={toggleFullScreen}>
        {!fullScreen ? <LuExpand /> : <BiCollapse />}
      </button>
      <div id="particles"></div>
      {page === 'Clock' && (
        <Clock />
      )}
      {page === 'Stopwatch' && (
        <Stopwatch />
      )}
      <div className="footer">Now - brought to you by Jin Wang</div>
    </div>
  );
}
