import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { VscDebugStart, VscDebugPause, VscDebugRestart } from 'react-icons/vsc';
import { FaRegKeyboard } from 'react-icons/fa';
import './Stopwatch.scss';
import KeyboardSettings from './KeyboardSettings';

function Stopwatch() {
  const [running, runningSetter] = useState(false);
  const [resetting, resettingSetter] = useState(false);
  const [startTime, startTimeSetter] = useState(moment());
  const [pauseTime, pauseTimeSetter] = useState(null);
  const [runtime, runtimeSetter] = useState(moment.duration(0));
  const [interval, intervalSetter] = useState(null);
  const [keyBind, keyBindSetter] = useState({});
  const [inSettings, inSettingsSetter] = useState(false);

  const start = () => {
    const newStartTime = moment();
    if (pauseTime !== null) {
      startTimeSetter(startTime.add(newStartTime.diff(pauseTime)));
      pauseTimeSetter(null);
      // Set up timer to update the clock text
      intervalSetter(
        setInterval(() => {
          runtimeSetter(moment.duration(moment().diff(startTime), 'milliseconds'));
        }, 7)
      );
    } else {
      startTimeSetter(newStartTime);
      runtimeSetter(moment.duration(0));
      // Set up timer to update the clock text
      intervalSetter(
        setInterval(() => {
          runtimeSetter(moment.duration(moment().diff(newStartTime), 'milliseconds'));
        }, 7)
      );
    }
    const startSound = new Audio('/sounds/startSound.mp3');
    startSound.play();
    runningSetter(true);
  };

  const stop = () => {
    runningSetter(false);
    pauseTimeSetter(moment());
    clearInterval(interval);
    const startSound = new Audio('/sounds/startSound.mp3');
    startSound.play();
  };

  const reset = () => {
    if (running) {
      return;
    }
    clearInterval(interval);
    intervalSetter(null);
    resettingSetter(true);
    runningSetter(false);
    pauseTimeSetter(null);
    runtimeSetter(moment.duration(0));
    const resetSound = new Audio('/sounds/resetSound.mp3');
    resetSound.play();
    setTimeout(() => {
      resettingSetter(false);
    }, 100);
  };

  const loadKeyBind = () => {
    // Load settings from local storage
    const settings = JSON.parse(localStorage.getItem('keyboardSettings'));
    keyBindSetter(settings || {
      startKey: ' ',
      resetKey: 'r',
    });
  };

  const keydownEvent = (e) => {
    if (e.key === keyBind.startKey) {
      e.preventDefault();
      e.stopPropagation();
      // Mimic button press
      document.querySelector('#startButton').classList.add('pressed');
    } else if (e.key === keyBind.resetKey) {
      e.preventDefault();
      e.stopPropagation();
      // Mimic button press
      document.querySelector('#resetButton').classList.add('pressed');
    }
  };

  const keyupEvent = (e) => {
    if (e.key === keyBind.startKey) {
      e.preventDefault();
      e.stopPropagation();
      // Mimic button click
      document.querySelector('#startButton').classList.remove('pressed');
      document.querySelector('#startButton').click();
    }
    if (e.key === keyBind.resetKey) {
      e.preventDefault();
      e.stopPropagation();
      // Mimic button click
      document.querySelector('#resetButton').classList.remove('pressed');
      document.querySelector('#resetButton').click();
    }
  };

  const addKeyBind = () => {
    // Bind keyboard shortcuts
    document.addEventListener('keydown', keydownEvent);
    document.addEventListener('keyup', keyupEvent);
  }

  const cleanKeyBind = () => {
    // Clean up
    document.removeEventListener('keydown', keydownEvent);
    document.removeEventListener('keyup', keyupEvent);
  };

  const toggleSettings = () => {
    if (inSettings) {
      addKeyBind();
      inSettingsSetter(false);
    } else {
      cleanKeyBind();
      inSettingsSetter(true);
    }
  };

  useEffect(() => {
    loadKeyBind();
    return cleanKeyBind;
  }, []);

  useEffect(() => {
    if (!inSettings) {
      cleanKeyBind();
      loadKeyBind();
    }
  }, [inSettings]);

  useEffect(() => {
    addKeyBind();
  }, [keyBind]);

  return (
    <div className={`stopwatch ${running ? 'running' : ''}${resetting ? 'reset' : ''}`}>
      <div className="shadow"></div>
      <div className="indicatorContainer">
        {Array.from({ length: 60 }).map((_, i) => (
          <div key={i} className={`indicator ${i % 5 === 0 ? 'majorIndicator' : ''}`} style={{ transform: `rotateZ(${i * 6}deg)` }}></div>
        ))}
      </div>
      <div className="hoursContainer">
        <div className="hours"></div>
      </div>
      <div className="minutesContainer">
        <div className="minutes"></div>
      </div>
      <div className="secondsContainer">
        <div className="seconds"></div>
      </div>
      <div className="infoFace">
        <p className="runTime">
          {runtime.hours().toString().padStart(2, '0')}
          :
          {runtime.minutes().toString().padStart(2, '0')}
          :
          {runtime.seconds().toString().padStart(2, '0')}
          .
          <small>{runtime.milliseconds().toString().padStart(3, '0')}</small>
        </p>
      </div>
      <div className="controls">
        <button id="startButton" className="startButton" onClick={!running ? start : stop}>
          {!running ? <VscDebugStart /> : <VscDebugPause />}
        </button>
        <button id="resetButton" className="resetButton" onClick={reset} disabled={running || !interval}>
          <VscDebugRestart />
        </button>
      </div>
      {inSettings ? (
        <KeyboardSettings exit={toggleSettings} className="desktopOnly" />
      ) : (
        <button className="settingsButton desktopOnly" onClick={toggleSettings}>
          <FaRegKeyboard />
        </button>
      )}
    </div>
  );
}

export default Stopwatch;
