import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './KeyboardSettings.scss';

function KeyboardSettings(props) {
  const [settings, settingsSetter] = useState({});
  const [settingStartKey, settingStartKeySetter] = useState(false);
  const [settingResetKey, settingResetKeySetter] = useState(false);

  useEffect(() => {
    // Load settings from local storage
    const settings = JSON.parse(localStorage.getItem('keyboardSettings'));
    settingsSetter(settings || {
      startKey: ' ',
      resetKey: 'r',
    });
  }, []);

  useEffect(() => {
    settingStartKeySetter(false);
    settingResetKeySetter(false);
    // Save settings to local storage
    localStorage.setItem('keyboardSettings', JSON.stringify(settings));
  }, [settings]);

  useEffect(() => {
    function keyBind (e) {
      e.preventDefault();
      settingsSetter({
        ...settings,
        startKey: e.key,
      });
      document.removeEventListener('keyup', keyBind, true);
    }
    if (settingStartKey) {
      document.addEventListener('keyup', keyBind, true);
    }
  }, [settingStartKey]);

  useEffect(() => {
    const keyBind = (e) => {
      e.preventDefault();
      settingsSetter({
        ...settings,
        resetKey: e.key,
      });
      document.removeEventListener('keyup', keyBind, true);
    };
    if (settingResetKey) {
      document.addEventListener('keyup', keyBind, true);
    }
  }, [settingResetKey]);

  return (
    <div className="keyboardSettings desktopOnly">
      <h1>Key Bindings</h1>
      <div className="keys">
        <button className={settingStartKey ? 'inSetting' : ''} onClick={() => settingStartKeySetter(true)}>
          <span className="function">Start/Pause</span>
          <span className="value">{settings.startKey}</span>
        </button>
        <button className={settingResetKey ? 'inSetting' : ''} onClick={() => settingResetKeySetter(true)}>
          <span className="function">Reset</span>
          <span className="value">{settings.resetKey}</span>
        </button>
      </div>
      <button className="save" onClick={props.exit}>Save</button>
    </div>
  );
}

KeyboardSettings.propTypes = {
  exit: PropTypes.func.isRequired,
};

export default KeyboardSettings;
